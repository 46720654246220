@include keyframes(zoomOut) {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
  100% {
    opacity: 0;
  }
}

@mixin zoomOut($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(zoomOut);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
