@keyframes sliderIn {
	0%{opacity: 0;}
    50% {opacity: 0; -webkit-transform: translateY(-20px);}
  100% {opacity: 1; -webkit-transform: translateY(0);}

}

@keyframes sliderOut {
	0%{opacity: 1;}
  30% {opacity: 0;}
}


@keyframes SpecialZoomIn{
    0%{
        transform:scale(1);
    }
    100%{
        transform:scale(1.15);
    }
}

@keyframes SpecialZoomOut{
    0%{
        transform:scale(1.15);
    }
    100%{
        transform:scale(1);
    }
}