@include keyframes(bounceInUp) {
  0% {opacity: 0; transform: translateY(2000px);}
  60% {opacity: 1; transform: translateY(-30px);}
  80% {transform: translateY(10px);opacity: 1;}
  100% {transform: translateY(0); opacity: 1;}
}

@mixin bounceInUp($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(bounceInUp);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
