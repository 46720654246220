@include keyframes(zoomInBig) {
  0% {
    opacity: 0;
    transform: scale(3);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100%{
    opacity: 1;
  }
}

@mixin zoomInBig($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(zoomInBig);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
