@include keyframes(fadeInDown) {
    0% {opacity: 0; -webkit-transform: translateY(-20px);}
  100% {opacity: 1; -webkit-transform: translateY(0);}
}

@mixin fadeInDown($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(fadeInDown);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
