@include keyframes(bounceOutUp) {
	0% {-webkit-transform: translateY(0);}
	20% {opacity: 1; -webkit-transform: translateY(20px);}
	100% {opacity: 0; -webkit-transform: translateY(-2000px);}
}

@mixin bounceOutUp($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(bounceOutUp);
  @include duration($duration);
	@include delay($delay);
	@include function($function);
	@include fill-mode($fill);
	@include visibility($visibility);
}
