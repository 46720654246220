@include keyframes(flip) {
  0% {transform: perspective(400px) rotateY(0); animation-timing-function: ease-out;}
  40% {transform: perspective(400px) translateZ(150px) rotateY(170deg); animation-timing-function: ease-out;}
  50% {transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1); animation-timing-function: ease-in;}
  80% {transform: perspective(400px) rotateY(360deg) scale(.95); animation-timing-function: ease-in;}
  100% {transform: perspective(400px) scale(1); animation-timing-function: ease-in;}
}

@mixin flip($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include transform-style(preserve-3d);
  @include animation-name(flip);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
