@include keyframes(flipInY) {
  0% {transform: perspective(400px) rotateY(90deg); opacity: 0;}
  40% {transform: perspective(400px) rotateY(-10deg);}
  70% {transform: perspective(400px) rotateY(10deg);}
  100% {transform: perspective(400px) rotateY(0deg); opacity: 1;}
}

@mixin flipInY($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(flipInY);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
