@include keyframes(rotateOutUpLeft) {
	0% {transform-origin: left bottom; transform: rotate(0); opacity: 1;}
 100% {transform-origin: left bottom; transform: rotate(-90deg); opacity: 0;}
}

@mixin rotateOutUpLeft($duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(rotateOutUpLeft);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
